<template>
  <div>
    <md-list-item class="md-layout">

      <div class="md-layout md-alignment-center md-xsmall-size-85">

        <div class="md-layout-item md-xsmall-size-100 md-small-hide">
          <image-item :path="object.image_path" alt="photo of the product"/>
        </div>

        <div class="md-layout-item md-xsmall-size-100 md-list-item-text">
          <span>{{ object.name }}</span>
        </div>

        <div class="md-layout-item md-xsmall-size-100">
          <span>CHF {{ object.price }}</span>
        </div>

        <div class="md-layout-item md-xsmall-size-100">
          <span>{{ object.stock }}</span>
          <span class="md-xsmall-10"> {{ object.stock > 1 ? 'pièces' : 'pièce' }}</span>
        </div>

        <div class="md-layout-item md-xsmall-size-100 md-list-item-text">
          <span>{{ object.category.name }}</span>
        </div>

        <div class="md-layout-item md-xsmall-size-100 md-list-item-text">
          <span>{{ object.game.name }}</span>
        </div>
      </div>

      <div class="md-layout md-layout-item md-size-10 md-small-20 md-xsmall-size-15">
        <div class="btn-edit">
          <md-button @click="onEdit" class="md-icon-button md-dense md-raised md-accent btn-green">
            <md-icon>mode</md-icon>
          </md-button>
        </div>
        <md-button @click="active = true" class="md-icon-button md-dense md-raised md-accent">
          <md-icon>delete</md-icon>
        </md-button>
      </div>

      <md-dialog-confirm
        :md-active.sync="active"
        md-title="Supprimer produit"
        md-content="Vous voulez vraiment supprimer cet produit?"
        md-confirm-text="Confirmer"
        md-cancel-text="Annuler"
        @md-confirm="onConfirm"/>

    </md-list-item>
    <md-divider class="md-full"/>
  </div>
</template>

<script>

import ProductService from '@/components/product/ProductService';
import ImageItem from '@/components/shared/image-item/ImageItem.vue';
import itemMixin from '@/mixins/item-mixin';

export default {
  name: 'ProductItem',
  data: () => ({
    service: ProductService,
  }),
  components: {
    ImageItem,
  },
  mixins: [itemMixin],
};

</script>
