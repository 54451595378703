<template>
  <div>
    <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false"
               :md-active.sync="isModalVisible">

      <md-dialog-content>
        <validator v-slot="{ handleSubmit }">
          <form novalidate @submit.prevent="handleSubmit(onSubmit)">

            <span class="md-headline">{{ title }}</span>

            <rule name="nom" rules="required" v-slot="{errors}">
              <md-field>
                <label>Nom</label>
                <md-input name="name" v-model="object.name"/>
                <span class="input-error">{{ errors[0] }}</span>
              </md-field>
            </rule>

            <md-field>
              <label>Description</label>
              <md-textarea v-model="object.description"></md-textarea>
            </md-field>

            <div class="md-layout md-gutter md-alignment-center">
              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <label>Jeu</label>
                  <md-select v-model="object.game_id" name="game">
                    <md-option v-for="game of games" :key="game.id" :value="game.id">
                      {{ game.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <label>Catégorie</label>
                  <md-select v-model="object.category_id" name="category">
                    <md-option v-for="category of categories" :key="category.id"
                               :value="category.id">
                      {{ category.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <label>Image</label>
                  <md-file type="file" accept="image/*" @change="addImage" :value="object.image_path"/>
                  <div>
                    <md-button class="md-icon-button md-accent md-dense" @click="removeImage">
                      <md-icon>delete</md-icon>
                    </md-button>
                    <md-tooltip md-direction="top">effacer l'image</md-tooltip>
                  </div>
                </md-field>

              </div>
              <div class="md-layout-item md-small-size-100">
                <rule name="prix" rules="required" v-slot="{errors}">
                  <md-field>
                    <label>Prix</label>
                    <md-input class="small-input" v-model="object.price" type="number"/>
                    <span class="input-error">{{ errors[0] }}</span>
                  </md-field>
                </rule>
              </div>

            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <rule name="stock" rules="required" v-slot="{errors}">
                <md-field>
                  <label>Stock</label>
                  <md-input class="small-input" v-model="object.stock" type="number"/>
                  <span class="input-error">{{ errors[0] }}</span>
                </md-field>
                </rule>
              </div>

              <div class="md-layout-item md-small-size-100">
                <rule name="stock min." rules="required" v-slot="{errors}">
                <md-field>
                  <label>Stock min.</label>
                  <md-input class="small-input" v-model="object.minimum_stock" type="number"/>
                  <span class="input-error">{{ errors[0] }}</span>
                </md-field>
                </rule>
              </div>
            </div>

            <div class="md-layout md-gutter md-alignment-center">
              <div class="md-layout-item md-small-size-100">
                <rule name="date" rules="required" v-slot="{errors}">
                <md-datepicker v-model="object.release_date" :md-model-type="String"
                               md-immediately>
                  <label>Date de sortie</label>
                  <span class="input-error">{{ errors[0] }}</span>
                </md-datepicker>
                </rule>
              </div>

              <div class="md-layout-item md-small-size-50">
                <md-field>
                  <label>Langue</label>
                  <md-select v-model="object.language_id" name="language">
                    <md-option v-for="language of languages" :key="language.id"
                               :value="language.id">
                      {{ language.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-50">
                <md-switch v-model="object.is_orderable">Commandable</md-switch>
              </div>
            </div>

            <div class="buttons-panel">
              <md-button class="md-raised md-accent" @click="close">Annuler</md-button>
              <md-button type="submit" class="md-raised md-primary">Valider</md-button>
            </div>

          </form>
        </validator>
      </md-dialog-content>
    </md-dialog>
    <div class="fab-space"/>
  </div>
</template>

<script>
import ProductService from '@/components/product/ProductService';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/fr.json';
import { mapGetters } from 'vuex';
import formMixin from '@/mixins/form-mixin';
import { DateTime } from 'luxon';
import { sqlDateFormat } from '@/date-format';

extend('required', {
  ...required,
  message: messages.required,
});

export default {
  name: 'ProductForm',
  data: () => ({
    model: 'Produit',
    service: ProductService,
  }),
  components: {
    rule: ValidationProvider,
    validator: ValidationObserver,
  },
  mixins: [formMixin],
  computed: {
    ...mapGetters([
      'categories',
      'languages',
      'games',
      'getFirstCategoryId',
      'getFirstLanguageId',
      'getFirstGameId',
    ]),
  },
  methods: {
    getDefaultObject() {
      return {
        category_id: this.getFirstCategoryId,
        language_id: this.getFirstLanguageId,
        game_id: this.getFirstGameId,
        is_orderable: true,
        release_date: DateTime.now().toFormat(sqlDateFormat),
      };
    },
    addImage(event) {
      const [image] = event.target.files;
      const object = { ...this.object, image, image_path: URL.createObjectURL(image) };
      this.$store.dispatch('setObject', object);
    },
    removeImage() {
      console.log(this.object);
      const object = { ...this.object, image: null, image_path: '' };
      this.$store.dispatch('setObject', object);
      console.log(this.object);
    },
  },
};
</script>

<style scoped>

/*noinspection ALL*/
md-field {
  margin: 4px 0 16px;
}
</style>
