<template>
  <div>
    <div class="md-layout md-alignment-center-space-between">
      <div class="heading md-layout md-alignment-center-space-between
            md-size-20 md-medium-20 md-small-100">
        <h1 class="md-layout-item md-size-10 md-medium-20 md-small-100">{{ title }}</h1>
        <md-button class="filter-button no-margin md-raised md-primary"
                   @click="showFilters = !showFilters">
          Filtres
        </md-button>
      </div>
      <div :class="{hideFilters:showFilters}"
           class="md-layout md-gutter md-layout-item md-alignment-center md-size-65 md-medium-size-80 md-small-size-100">
        <div class="md-layout-item">
          <md-field>
            <label>Nom</label>
            <md-input @keyup.enter="search" v-model="filter.name"/>
          </md-field>
        </div>

        <div class="md-layout-item">
          <md-field>
            <label>Jeux</label>
            <md-select v-model="filter.game_id" name="game">
              <md-option value="">Tous</md-option>
              <md-option v-for="game of games" :key="game.id" :value="game.id">
                {{ game.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item">
          <md-field>
            <label>Catégorie</label>
            <md-select v-model="filter.category_id" name="game">
              <md-option value="">Tous</md-option>
              <md-option v-for="category of categories" :key="category.id" :value="category.id">
                {{ category.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <md-button @click="search" class="md-icon-button md-primary">
          <md-icon>search</md-icon>
        </md-button>

      </div>
    </div>

    <md-list-item class="toolbar md-layout md-subheading">
      <div class="md-layout md-alignment-center md-xsmall-size-100">
        <div class="md-layout-item md-small-hide"></div>
        <div class="md-layout-item md-xsmall-size-100"><span>Nom</span></div>
        <div class="md-layout-item md-xsmall-size-100"><span>Prix</span></div>
        <div class="md-layout-item md-xsmall-size-100"><span>Stock</span></div>
        <div class="md-layout-item md-xsmall-size-100 "><span>Catégorie</span></div>
        <div class="md-layout-item md-xsmall-size-100 "><span>Jeu</span></div>
        <div class="md-layout-item md-size-10 md-small-20 md-xsmall-size-10"></div>
      </div>
    </md-list-item>

    <md-list>
      <product-item v-for="product of objects" :key="product.id" :object="product"
                    @deleted="removeObject"/>
    </md-list>

    <pagination v-model="page" :records="total" :per-page="20" @paginate="getNext"
                :options="options"/>

    <product-form @submit="saveObject"/>
  </div>
</template>

<script>
import ProductItem from '@/components/product/ProductItem.vue';
import ProductForm from '@/components/product/ProductForm.vue';
import CategoryService from '@/components/category/CategoryService';
import LanguageService from '@/components/language/LanguageService';
import GameService from '@/components/game/GameService';
import ProductService from '@/components/product/ProductService';
import pageMixin from '@/mixins/page-mixin';
import paginationMixin from '@/mixins/pagination-mixin';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductsPage',
  data: () => ({
    title: 'Produits',
    service: ProductService,
  }),
  components: {
    ProductItem,
    ProductForm,
  },
  computed: {
    ...mapGetters(['games', 'categories']),
  },
  mixins: [pageMixin, paginationMixin],
  async mounted() {
    try {
      await this.getFirst();
      const addingCategories = this.$store.dispatch('addCategories', await CategoryService.index());
      const addingLanguages = this.$store.dispatch('addLanguages', await LanguageService.index());
      const addingGames = this.$store.dispatch('addGames', await GameService.index());
      await Promise.all([addingCategories, addingLanguages, addingGames]);
    } catch (err) {
      this.handleError(err);
    }
  },
};
</script>
